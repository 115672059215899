import store from './store';

export function setSiteTitle() {
  if (!store.state.defaultLanguage) {
    return;
  }

  const defaultLang = store.state.defaultLanguage.toLowerCase();
  const translations = JSON.parse(store.state.languageLabel);
  let siteTitle = translations.label.site_title || 'Home';
  if (store.state.siteTitle
    && store.state.siteTitle.translations
    && store.state.siteTitle.translations.length
  ) {
    const siteTranslationArray = store.state.siteTitle.translations;
    const data = siteTranslationArray.find((item) => item.lang === defaultLang);
    if (data && data.title) {
      siteTitle = data.title;
    }
  }
  document.title = siteTitle;
}

/**
 * Get all files from a specific file path
 *
 * @param {function} requireContext from require.context()
 *
 * @return {object}
 */
export function retrieveObject(requireContext) {
  return requireContext.keys()
    .map(file => [
      file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)
    ])
    .reduce((modules, [name, module]) => {
        modules[name] = module.default
        return modules
    }, {});
}

/**
 * Check if string is valid UUID
 *
 * https://melvingeorge.me/blog/check-if-string-valid-uuid-regex-javascript
 *
 * @param {string} str
 *
 * @returns {boolean}
 */
export function isUuid(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
}
