// GENERAL MUTATIONS
export const UPDATE_STATE = 'UPDATE_STATE';
export const CLEAR_STATE = 'CLEAR_STATE';
export const LOADING_STATE = 'LOADING_STATE';

// STRIPE MUTATIONS
export const STRIPE_INIT = 'STRIPE_INIT';
export const STRIPE_ELEMENT_INIT = 'STRIPE_ELEMENT_INIT';
export const STRIPE_ERROR = 'STRIPE_ERROR';

// DONATION MUTATIONS
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const SET_SOCIAL_SHARING_SITES = 'SET_SOCIAL_SHARING_SITES';

// MISSION MUTATIONS
export const GET_MISSION_SUCCESS = 'GET_MISSION_SUCCESS';
export const GET_MISSION_QUESTIONS_SUCCESS = 'GET_MISSION_QUESTIONS_SUCCESS';
export const RESET_QUESTIONS = 'RESET_QUESTIONS';

// PAYMENT MUTATIONS
export const PAYMENT_SUBMIT = 'PAYMENT_SUBMIT';
export const PAYMENT_CLEAR = 'PAYMENT_CLEAR';
export const UPDATE_SAVE_PAYMENT_METHOD = 'UPDATE_SAVE_PAYMENT_METHOD';
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';

// PAYMENT METHOD MUTATIONS
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';
export const STORE_PAYMENT_METHOD_SUCCESS = 'STORE_PAYMENT_METHOD_SUCCESS';
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS';
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';

// CUSTOMER MUTATIONS
export const STORE_PAYMENT_METHOD_STRIPE = 'STORE_PAYMENT_METHOD_STRIPE';
export const SET_ACTIVE_PAYMENT_METHOD = 'SET_ACTIVE_PAYMENT_METHOD';
export const SET_WITHOUT_PAYMENT_METHOD = 'SET_WITHOUT_PAYMENT_METHOD';
export const CLEAR_PAYMENT_METHOD_ERROR = 'CLEAR_PAYMENT_METHOD_ERROR';
export const SET_DEFAULT_COUNTRY = 'SET_DEFAULT_COUNTRY';
export const GET_AMOUNT_TIERS_SUCCESS = 'GET_AMOUNT_TIERS_SUCCESS';
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS_SUCCESS';

export const SET_ISO_COMPLIANT_COUNTRIES = 'SET_ISO_COMPLIANT_COUNTRIES';

// TEAM VOLUNTEERING MUTATIONS
export const GET_CURRENT_USER_ID = 'GET_CURRENT_USER_ID';
export const GET_TEAM_USERS_LIST_SUCCESS = 'GET_TEAM_USERS_LIST_SUCCESS';
export const GET_ALL_TEAM_USERS_LIST_SUCCESS = 'GET_ALL_TEAM_USERS_LIST_SUCCESS';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_TEAM_DETAILS_SUCCESS = 'GET_TEAM_DETAILS_SUCCESS';
export const STORE_TEAM_DETAILS_SUCCESS = 'STORE_TEAM_DETAILS_SUCCESS';
export const RESET_TEAM_DETAILS = 'RESET_TEAM_DETAILS';
export const ARCHIVE_TEAM_SUCCESS = 'ARCHIVE_TEAM_SUCCESS';
export const STORE_TEAM_MEMBERS_SUCCESS = 'STORE_TEAM_MEMBERS_SUCCESS';

// PRE SCREENING MUTATIONS
export const LIST_PRE_SCREENING_STEPS_SUCCESS = 'LIST_PRE_SCREENING_STEPS_SUCCESS';
export const STORE_PRE_SCREENING_APPLICATION = 'STORE_PRE_SCREENING_APPLICATION';

// NEW TEAM VOLUNTEERING MUTATIONS
export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_TEAM_LIST = 'GET_TEAM_LIST';
export const GET_TEAM_SETUP = 'GET_TEAM_SETUP';
export const UPDATE_TEAM_MEMBER_ROLE = 'UPDATE_TEAM_MEMBER_ROLE_SUCCESS';
export const DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER_SUCCESS';
export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER_SUCCESS';
export const GET_TEAM_APPLICATIONS = 'GET_TEAM_APPLICATIONS';

// MISSION APPLICATION
export const CANCEL_APPLICATION_SUCCESS = 'CANCEL_APPLICATION_SUCCESS';
