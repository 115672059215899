export const MODULES = {
  STRIPE: 'stripe',
  DONATION: 'donation',
  MISSION: 'mission',
  PAYMENT: 'payment',
  CUSTOMER: 'customer',
  TEAM: 'team',
  PRESCREENING: 'prescreening',
  TEAMVOLUNTEERING: 'teamVolunteering',
  MISSIONAPPLICATION: 'missionApplication'
};

export const SETTINGS = {
  ONE_HUNDRED_PERCENT_DONATION: '100_percent_donation',
  WHITELABEL_RECEIPT: 'whitelabel_receipt',
  ANONYMOUS_DONATION: 'anonymous_donation'
};

export const PAYMENT_GATEWAY = {
  STRIPE: 'STRIPE'
};

export const PAYMENT_GATEWAY_TYPE = {
  CARD: 'CARD'
};

export const AMOUNT_MARKUP = 0.03;

export const DONATION_FEE = 0.05;

export const DONATION_FIXED_FEES = {
  EUR: 0.25,
  USD: 0.30,
  CAD: 0.30,
  GBP: 0.20,
  CHF: 0.30
};

export const PAYMENT_GATEWAY_FEE = 0.30;

export const STRIPE_PAYMENT_GATEWAY_TYPE_ID = 1;

export const PAYMENT_GATEWAY_KEYS_ERRORS = [
  'Missing payment gateway keys.',
  'Payment gateway unauthorized.',
  'Payment gateway unknown failure.'
];

export const DONATION_TYPE = {
  ALL: 'ALL',
  ONE_TIME: 'ONE_TIME',
  RECURRING: 'RECURRING'
};

export const PAYMENT_STATUS = {
  1: 'success',
  2: 'failed'
};

export const FREQUENCY = {
  ONCE: 'ONCE',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  YEARLY: 'YEARLY'
};

export const TEAM_USER_ROLE = {
  ADMIN: 'ADMIN',
  LEADER: 'LEADER',
  MEMBER: 'MEMBER'
};

export const TEAM_MESSAGING_ROLE = {
  NONE: 'NONE',
  LEADERS_MEMBERS: 'LEADERS_MEMBERS',
  LEADERS: 'LEADERS'
};

/**
 * List of social sites we use for sharing our mission
 */
export const SOCIAL_SITES = {
  facebook: {
    title: 'Facebook',
    image: {
      main: 'facebook-white.svg',
      detailNormal: 'facebook-ic-gray.svg',
      detailHover: 'facebook-ic-gray-h.svg',
    },
    isEnabled: false
  },
  twitter: {
    title: 'Twitter',
    image: {
      main: 'twitter-white.svg',
      detailNormal: 'twitter-ic-gray.svg',
      detailHover: 'twitter-ic-gray-h.svg',
    },
    isEnabled: false
  },
  linkedin: {
    title: 'LinkedIn',
    image: {
      main: 'linkedin-circle.svg',
      detailNormal: 'linkedin-ic-grey.svg',
      detailHover: 'linkedin-ic-white.svg',
    },
    isEnabled: false
  }
}

// payment flow

export const DONATION_SCREENS = {
  DONATION_BILLING: 'DonationBilling',
  DONATION_AMOUNT: 'DonationAmount',
  DONATION_REVIEW: 'DonationReview',
  DONATION_CARD: 'DonationCard'
}

/**
 * List of all subscription types
 */
export const SUBSCRIPTION_STATES = {
  ACTIVATED: 'ACTIVATED',
  PAUSED: 'PAUSED',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED'
}

/**
 * List of all program approval types
 */
export const PROGRAM_APPROVAL_TYPES = {
  MANUAL: 'MANUAL',
  AUTOMATIC: 'AUTOMATIC'
}

/**
 * List of all program approval types
 */
export const APPLICATION_STATUES = {
  WAIT_LIST: 'WAIT_LIST',
  PENDING: 'PENDING',
  AUTOMATICALLY_APPROVED: 'AUTOMATICALLY_APPROVED',
  ABORTED: 'ABORTED',
  DECLINED: 'DECLINED',
  REFUSED: 'REFUSED',
  ONGOING: 'ONGOING',
  COMPLETED: 'COMPLETED'
}
